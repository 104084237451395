define(['app'], function (app) {

  const personalisationFontDropdown = function() {

    const component = {};

    component.config = {
      selectors: {
        overlayBoxes: '.productImageCarousel_personalisationOverlay',
        button: '.personalisation_dropdown_button',
        list: '.personalisation_dropdown_list',
        fontName: '.personalisation_fontDropdownName',
      },
      attribs: {
        selectedFont: 'data-selected-personalisation-font',
        font: 'data-font',
        fontId: 'data-font-id',
        fontWeight: 'data-font-weight'
      },
      classNames: {
        fontName: 'personalisation_fontDropdownName',
        focusedItem: 'focusedItem',
      },
    }

    component.init = (element) => {

      component.element = element;

      component.overlayBoxes = document.querySelectorAll(component.config.selectors.overlayBoxes);
      component.dropdownButton = component.element.querySelector(component.config.selectors.button);
      component.dropdownList = component.element.querySelector(component.config.selectors.list);
      component.dropdownListItems = component.dropdownList.querySelectorAll('li');
      component.setNewSelectedItem(component.dropdownListItems[0]);
      component.focusedIndex = 0;
      component.addEventListeners();

      return component;

    };

    component.addEventListeners = () => {
      component.dropdownButton.addEventListener('click', component.toggleDropdown);

      component.dropdownList.addEventListener('keydown', component.handleKeyboardNavigation);

      component.element.addEventListener('keydown', component.handleTabAway);

      component.dropdownListItems.forEach((item) => {
        item.addEventListener('click', component.handleListClick);
        item.addEventListener('mouseover', component.handleHover);
      })
    }

    component.handleTabAway = (e) => {
      if (e.key === 'Tab' && component.element.contains(document.activeElement)){
        component.dropdownList.hidden = true;
        component.dropdownButton.setAttribute('aria-expanded', false);
        component.dropdownList.removeAttribute('aria-activedescendant');
      }
    }

    component.handleListClick = (e) => {
      let target = e.target;
      if(e.target.classList.contains(component.config.classNames.fontName)){
        target = e.target.parentElement;
      }
      component.setNewSelectedItem(target);
      component.toggleDropdown();
    }

    component.handleHover = (e) => {
      let target = e.target;
      if(e.target.classList.contains(component.config.classNames.fontName)){
        target = e.target.parentElement;
      }
      component.setFocusedListItem(target);
    }

    component.setNewFont = (listElement) => {
      const newFont = listElement.getAttribute(component.config.attribs.font);
      const newFontWeight = listElement.getAttribute(component.config.attribs.fontWeight);
      const newFontId = listElement.getAttribute(component.config.attribs.fontId);
      const innerText = listElement.querySelector(component.config.selectors.fontName).innerText;
      const styles = {
        'font-family': newFont,
        'font-weight': newFontWeight
      };
      Object.assign(component.dropdownButton.querySelector(component.config.selectors.fontName).style, styles);
      component.dropdownButton.setAttribute(component.config.attribs.font, newFont);
      component.dropdownButton.setAttribute(component.config.attribs.weight, newFontWeight);
      component.dropdownButton.setAttribute(component.config.attribs.fontId, newFontId);
      component.dropdownButton.querySelector(component.config.selectors.fontName).innerText = innerText;

      component.changeFont(newFont, newFontWeight);
    }

    component.handleKeyboardNavigation = (e) => {
      const key = e.key;
      let newIndex = component.focusedIndex;
      switch (key){
        case 'Down':
        case 'ArrowDown':
          (newIndex < (component.dropdownListItems.length - 1)) ? (newIndex +=1) : (newIndex=0);
          component.setFocusedListItem(component.dropdownListItems[newIndex]);
          e.preventDefault();
          break;
        case 'ArrowUp':
        case 'Up':
          newIndex > 0 ? newIndex -= 1 : newIndex=component.dropdownListItems.length - 1;
          component.setFocusedListItem(component.dropdownListItems[newIndex]);
          e.preventDefault();
          break;
        case 'Enter':
          component.setNewSelectedItem(component.dropdownListItems[newIndex]);
          component.setFocusedListItem(component.dropdownListItems[0]);
          component.toggleDropdown();
          e.preventDefault();
          break;
        case 'Esc':
        case 'Escape':
          component.toggleDropdown();
          break;
        default:
          return;
      }
    }

    component.toggleDropdown = () => {
      const expanded = !component.dropdownList.hidden;
      component.dropdownList.hidden = expanded;

      if(expanded){
        component.dropdownButton.setAttribute('aria-expanded', false);
        component.dropdownList.removeAttribute('aria-activedescendant');
        component.dropdownButton.focus();
      }else{
        component.dropdownButton.setAttribute('aria-expanded', true);
        component.setFocusedListItem(component.dropdownListItems[0]);
        component.dropdownList.focus();
      }
    }

    component.setNewSelectedItem = (element) => {
      component.selectedListItem && component.selectedListItem.setAttribute('aria-selected', false);
      component.selectedListItem = element;
      component.selectedListItem.setAttribute('aria-selected', true);

      component.setNewFont(component.selectedListItem);
    }

    component.setFocusedListItem = (element) => {
      component.focussedListItem && component.focussedListItem.classList.remove(component.config.classNames.focusedItem);
      component.focussedListItem = element;
      component.focussedListItem.classList.add(component.config.classNames.focusedItem);
      component.dropdownList.setAttribute('aria-activedescendant', component.focussedListItem.id);
      component.setFocusedIndex(element);
    }

    component.setFocusedIndex = (element) => {
      for(let i=0; i<component.dropdownListItems.length; i++){
        if(element === component.dropdownListItems[i]){
          component.focusedIndex = i;
          break;
        }
      }
    }

    component.changeFont = (fontStyle, fontWeight) => {
      component.overlayBoxes.forEach((output) => {
        output.style.fontFamily = fontStyle;
        output.style.fontWeight = fontWeight;
      });
    }

    return component;

  };

  return personalisationFontDropdown;

});
